module.exports = {
  firebaseConfig: {
    apiKey: 'AIzaSyA7uuKMKbPjNIY7C8c5a--mV6jnFxz3jUw',
    authDomain: 'vinsyt-prod.firebaseapp.com',
    projectId: 'vinsyt-prod',
    storageBucket: 'vinsyt-prod.appspot.com',
    messagingSenderId: '84043755306',
    appId: '1:84043755306:web:765827667925e45b7ff853',
    measurementId: 'G-7XR2NELMVV'
  },
  gtmId: 'GTM-TLCRFWM'
};
