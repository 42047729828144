module.exports = {
  firebaseConfig: {
    apiKey: 'AIzaSyBMjrf6x0ycG8kQiKwKcK2UZEqV-Wd8Qgs',
    authDomain: 'vinsyt-stage.firebaseapp.com',
    projectId: 'vinsyt-stage',
    storageBucket: 'vinsyt-stage.appspot.com',
    messagingSenderId: '867209407256',
    appId: '1:867209407256:web:f5c79c54c7ebd0eec24c6d',
    measurementId: 'G-ME9TM6529J'
  },
  gtmId: 'GTM-TLCRFWM'
};
